/* purgecss start ignore */

table[fr-original-class='fr-alternate-rows'] tbody tr:nth-child(2n),
table[fr-original-class='fr-dashed-borders fr-alternate-rows']
  tbody
  tr:nth-child(2n) {
  background: whitesmoke !important;
}

.share-badge.ant-badge {
  display: block !important;
}

/* purgecss end ignore */

/* Start purging... */

/* Stop purging. */

/* Start purging... */

.ehq-absolute {
  position: absolute !important
}

.ehq-relative {
  position: relative !important
}

.ehq-top-3 {
  top: 0.75rem !important
}

.ehq-right-0 {
  right: 0px !important
}

.ehq-right-4 {
  right: 1rem !important
}

.ehq-bottom-0 {
  bottom: 0px !important
}

.ehq-left-0 {
  left: 0px !important
}

.ehq-float-right {
  float: right !important
}

.ehq-m-0 {
  margin: 0px !important
}

.ehq-m-6 {
  margin: 1.5rem !important
}

.ehq-m-auto {
  margin: auto !important
}

.ehq-m-px {
  margin: 1px !important
}

.ehq-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important
}

.ehq-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important
}

.ehq-mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important
}

.ehq-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.ehq-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.ehq-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.ehq-my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.ehq-my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important
}

.ehq-my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.ehq-my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important
}

.ehq-my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.ehq-mt-0 {
  margin-top: 0px !important
}

.ehq-mt-1 {
  margin-top: 0.25rem !important
}

.ehq-mt-2 {
  margin-top: 0.5rem !important
}

.ehq-mt-3 {
  margin-top: 0.75rem !important
}

.ehq-mt-4 {
  margin-top: 1rem !important
}

.ehq-mt-5 {
  margin-top: 1.25rem !important
}

.ehq-mt-6 {
  margin-top: 1.5rem !important
}

.ehq-mt-8 {
  margin-top: 2rem !important
}

.ehq-mt-10 {
  margin-top: 2.5rem !important
}

.ehq-mt-16 {
  margin-top: 4rem !important
}

.ehq-mt-32 {
  margin-top: 8rem !important
}

.ehq-mt-0\.5 {
  margin-top: 0.125rem !important
}

.ehq--mt-1 {
  margin-top: -0.25rem !important
}

.ehq--mt-8 {
  margin-top: -2rem !important
}

.ehq-mr-0 {
  margin-right: 0px !important
}

.ehq-mr-1 {
  margin-right: 0.25rem !important
}

.ehq-mr-2 {
  margin-right: 0.5rem !important
}

.ehq-mr-3 {
  margin-right: 0.75rem !important
}

.ehq-mr-4 {
  margin-right: 1rem !important
}

.ehq-mr-5 {
  margin-right: 1.25rem !important
}

.ehq-mr-6 {
  margin-right: 1.5rem !important
}

.ehq-mr-2\.5 {
  margin-right: 0.625rem !important
}

.ehq-mb-0 {
  margin-bottom: 0px !important
}

.ehq-mb-1 {
  margin-bottom: 0.25rem !important
}

.ehq-mb-2 {
  margin-bottom: 0.5rem !important
}

.ehq-mb-3 {
  margin-bottom: 0.75rem !important
}

.ehq-mb-4 {
  margin-bottom: 1rem !important
}

.ehq-mb-5 {
  margin-bottom: 1.25rem !important
}

.ehq-mb-6 {
  margin-bottom: 1.5rem !important
}

.ehq-mb-8 {
  margin-bottom: 2rem !important
}

.ehq--mb-2 {
  margin-bottom: -0.5rem !important
}

.ehq-ml-1 {
  margin-left: 0.25rem !important
}

.ehq-ml-2 {
  margin-left: 0.5rem !important
}

.ehq-ml-4 {
  margin-left: 1rem !important
}

.ehq-ml-5 {
  margin-left: 1.25rem !important
}

.ehq-ml-10 {
  margin-left: 2.5rem !important
}

.ehq-ml-16 {
  margin-left: 4rem !important
}

.ehq-ml-auto {
  margin-left: auto !important
}

.ehq-ml-1\.5 {
  margin-left: 0.375rem !important
}

.ehq-block {
  display: block !important
}

.ehq-inline-block {
  display: inline-block !important
}

.ehq-inline {
  display: inline !important
}

.ehq-flex {
  display: flex !important
}

.ehq-hidden {
  display: none !important
}

.ehq-h-0 {
  height: 0px !important
}

.ehq-h-1 {
  height: 0.25rem !important
}

.ehq-h-3 {
  height: 0.75rem !important
}

.ehq-h-4 {
  height: 1rem !important
}

.ehq-h-6 {
  height: 1.5rem !important
}

.ehq-h-7 {
  height: 1.75rem !important
}

.ehq-h-10 {
  height: 2.5rem !important
}

.ehq-h-12 {
  height: 3rem !important
}

.ehq-h-48 {
  height: 12rem !important
}

.ehq-h-60 {
  height: 15rem !important
}

.ehq-h-64 {
  height: 16rem !important
}

.ehq-h-96 {
  height: 24rem !important
}

.ehq-h-auto {
  height: auto !important
}

.ehq-h-full {
  height: 100% !important
}

.ehq-h-screen {
  height: 100vh !important
}

.ehq-w-0 {
  width: 0px !important
}

.ehq-w-3 {
  width: 0.75rem !important
}

.ehq-w-8 {
  width: 2rem !important
}

.ehq-w-16 {
  width: 4rem !important
}

.ehq-w-20 {
  width: 5rem !important
}

.ehq-w-32 {
  width: 8rem !important
}

.ehq-w-64 {
  width: 16rem !important
}

.ehq-w-auto {
  width: auto !important
}

.ehq-w-1\/2 {
  width: 50% !important
}

.ehq-w-1\/3 {
  width: 33.333333% !important
}

.ehq-w-2\/4 {
  width: 50% !important
}

.ehq-w-4\/5 {
  width: 80% !important
}

.ehq-w-3\/6 {
  width: 50% !important
}

.ehq-w-5\/6 {
  width: 83.333333% !important
}

.ehq-w-1\/12 {
  width: 8.333333% !important
}

.ehq-w-9\/12 {
  width: 75% !important
}

.ehq-w-11\/12 {
  width: 91.666667% !important
}

.ehq-w-full {
  width: 100% !important
}

.ehq-min-w-full {
  min-width: 100% !important
}

.ehq-max-w-xs {
  max-width: 20rem !important
}

.ehq-max-w-md {
  max-width: 28rem !important
}

.ehq-max-w-xl {
  max-width: 36rem !important
}

.ehq-max-w-full {
  max-width: 100% !important
}

.ehq-flex-1 {
  flex: 1 1 !important
}

.ehq-flex-auto {
  flex: 1 1 auto !important
}

.ehq-origin-center {
  transform-origin: center !important
}

.ehq-transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.ehq-rotate-90 {
  --tw-rotate: 90deg !important
}

@-webkit-keyframes ehq-spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ehq-spin {
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ehq-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ehq-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes ehq-pulse {
  50% {
    opacity: .5
  }
}

@keyframes ehq-pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes ehq-bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes ehq-bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.ehq-cursor-pointer {
  cursor: pointer !important
}

.ehq-cursor-move {
  cursor: move !important
}

.ehq-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important
}

.ehq-flex-col {
  flex-direction: column !important
}

.ehq-flex-wrap {
  flex-wrap: wrap !important
}

.ehq-items-center {
  align-items: center !important
}

.ehq-justify-start {
  justify-content: flex-start !important
}

.ehq-justify-end {
  justify-content: flex-end !important
}

.ehq-justify-center {
  justify-content: center !important
}

.ehq-justify-between {
  justify-content: space-between !important
}

.ehq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important
}

.ehq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important
}

.ehq-overflow-hidden {
  overflow: hidden !important
}

.ehq-overflow-y-auto {
  overflow-y: auto !important
}

.ehq-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.ehq-whitespace-normal {
  white-space: normal !important
}

.ehq-whitespace-nowrap {
  white-space: nowrap !important
}

.ehq-break-all {
  word-break: break-all !important
}

.ehq-rounded-none {
  border-radius: 0px !important
}

.ehq-rounded-sm {
  border-radius: 0.125rem !important
}

.ehq-rounded {
  border-radius: 0.25rem !important
}

.ehq-rounded-xl {
  border-radius: 0.75rem !important
}

.ehq-rounded-full {
  border-radius: 9999px !important
}

.ehq-rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.ehq-rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important
}

.ehq-rounded-l-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important
}

.ehq-border-0 {
  border-width: 0px !important
}

.ehq-border {
  border-width: 1px !important
}

.ehq-border-t-0 {
  border-top-width: 0px !important
}

.ehq-border-t-4 {
  border-top-width: 4px !important
}

.ehq-border-t {
  border-top-width: 1px !important
}

.ehq-border-r-0 {
  border-right-width: 0px !important
}

.ehq-border-r {
  border-right-width: 1px !important
}

.ehq-border-b-0 {
  border-bottom-width: 0px !important
}

.ehq-border-b {
  border-bottom-width: 1px !important
}

.ehq-border-l-0 {
  border-left-width: 0px !important
}

.ehq-border-solid {
  border-style: solid !important
}

.ehq-border-none {
  border-style: none !important
}

.ehq-border-blue-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important
}

.ehq-border-blue-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important
}

.ehq-border-green-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity)) !important
}

.ehq-border-red-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important
}

.ehq-border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity)) !important
}

.ehq-border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity)) !important
}

.ehq-border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(161, 161, 170, var(--tw-border-opacity)) !important
}

.ehq-border-red-6 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 34, 45, var(--tw-border-opacity)) !important
}

.focus\:ehq-border-blue-7:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgba(9, 109, 217, var(--tw-border-opacity)) !important
}

.ehq-bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important
}

.ehq-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important
}

.ehq-bg-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important
}

.ehq-bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important
}

.ehq-bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-3 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important
}

.ehq-bg-gray-4 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(240, 240, 240, var(--tw-bg-opacity)) !important
}

.hover\:ehq-bg-purple-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 232, 255, var(--tw-bg-opacity)) !important
}

.hover\:ehq-bg-blue-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important
}

.focus\:ehq-bg-blue-7:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(9, 109, 217, var(--tw-bg-opacity)) !important
}

.ehq-bg-cover {
  background-size: cover !important
}

.ehq-bg-center {
  background-position: center !important
}

.ehq-object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important
}

.ehq-p-0 {
  padding: 0px !important
}

.ehq-p-2 {
  padding: 0.5rem !important
}

.ehq-p-3 {
  padding: 0.75rem !important
}

.ehq-p-4 {
  padding: 1rem !important
}

.ehq-p-6 {
  padding: 1.5rem !important
}

.ehq-p-8 {
  padding: 2rem !important
}

.ehq-p-10 {
  padding: 2.5rem !important
}

.ehq-p-2\.5 {
  padding: 0.625rem !important
}

.ehq-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important
}

.ehq-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.ehq-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.ehq-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important
}

.ehq-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}

.ehq-px-1\.5 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important
}

.ehq-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important
}

.ehq-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important
}

.ehq-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.ehq-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important
}

.ehq-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.ehq-py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.ehq-py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important
}

.ehq-py-0\.5 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important
}

.ehq-pt-0 {
  padding-top: 0px !important
}

.ehq-pt-1 {
  padding-top: 0.25rem !important
}

.ehq-pt-2 {
  padding-top: 0.5rem !important
}

.ehq-pt-3 {
  padding-top: 0.75rem !important
}

.ehq-pt-4 {
  padding-top: 1rem !important
}

.ehq-pt-6 {
  padding-top: 1.5rem !important
}

.ehq-pt-8 {
  padding-top: 2rem !important
}

.ehq-pt-9 {
  padding-top: 2.25rem !important
}

.ehq-pt-10 {
  padding-top: 2.5rem !important
}

.ehq-pr-1 {
  padding-right: 0.25rem !important
}

.ehq-pr-2 {
  padding-right: 0.5rem !important
}

.ehq-pr-3 {
  padding-right: 0.75rem !important
}

.ehq-pr-4 {
  padding-right: 1rem !important
}

.ehq-pr-5 {
  padding-right: 1.25rem !important
}

.ehq-pr-8 {
  padding-right: 2rem !important
}

.ehq-pb-0 {
  padding-bottom: 0px !important
}

.ehq-pb-2 {
  padding-bottom: 0.5rem !important
}

.ehq-pb-3 {
  padding-bottom: 0.75rem !important
}

.ehq-pb-4 {
  padding-bottom: 1rem !important
}

.ehq-pb-5 {
  padding-bottom: 1.25rem !important
}

.ehq-pb-6 {
  padding-bottom: 1.5rem !important
}

.ehq-pl-0 {
  padding-left: 0px !important
}

.ehq-pl-1 {
  padding-left: 0.25rem !important
}

.ehq-pl-2 {
  padding-left: 0.5rem !important
}

.ehq-pl-4 {
  padding-left: 1rem !important
}

.ehq-pl-5 {
  padding-left: 1.25rem !important
}

.ehq-pl-6 {
  padding-left: 1.5rem !important
}

.ehq-text-left {
  text-align: left !important
}

.ehq-text-center {
  text-align: center !important
}

.ehq-text-right {
  text-align: right !important
}

.ehq-align-top {
  vertical-align: top !important
}

.ehq-align-middle {
  vertical-align: middle !important
}

.ehq-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.ehq-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.ehq-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.ehq-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.ehq-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.ehq-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.ehq-text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important
}

.ehq-font-normal {
  font-weight: 400 !important
}

.ehq-font-semibold {
  font-weight: 600 !important
}

.ehq-font-bold {
  font-weight: 700 !important
}

.ehq-font-black {
  font-weight: 900 !important
}

.ehq-uppercase {
  text-transform: uppercase !important
}

.ehq-capitalize {
  text-transform: capitalize !important
}

.ehq-leading-4 {
  line-height: 1rem !important
}

.ehq-leading-5 {
  line-height: 1.25rem !important
}

.ehq-leading-6 {
  line-height: 1.5rem !important
}

.ehq-leading-7 {
  line-height: 1.75rem !important
}

.ehq-leading-8 {
  line-height: 2rem !important
}

.ehq-leading-none {
  line-height: 1 !important
}

.ehq-leading-snug {
  line-height: 1.375 !important
}

.ehq-leading-normal {
  line-height: 1.5 !important
}

.ehq-leading-relaxed {
  line-height: 1.625 !important
}

.ehq-leading-loose {
  line-height: 2 !important
}

.ehq-tracking-tight {
  letter-spacing: -0.025em !important
}

.ehq-text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important
}

.ehq-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
}

.ehq-text-blue-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important
}

.ehq-text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important
}

.ehq-text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important
}

.ehq-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important
}

.ehq-text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important
}

.ehq-text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important
}

.ehq-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-text-opacity)) !important
}

.ehq-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(113, 113, 122, var(--tw-text-opacity)) !important
}

.ehq-text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(82, 82, 91, var(--tw-text-opacity)) !important
}

.ehq-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(39, 39, 42, var(--tw-text-opacity)) !important
}

.ehq-text-gray-7 {
  --tw-text-opacity: 1 !important;
  color: rgba(140, 140, 140, var(--tw-text-opacity)) !important
}

.ehq-text-gray-8 {
  --tw-text-opacity: 1 !important;
  color: rgba(89, 89, 89, var(--tw-text-opacity)) !important
}

.ehq-text-gray-9 {
  --tw-text-opacity: 1 !important;
  color: rgba(67, 67, 67, var(--tw-text-opacity)) !important
}

.ehq-text-gray-13 {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important
}

.ehq-text-red-6 {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 34, 45, var(--tw-text-opacity)) !important
}

.focus\:ehq-text-white:focus {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important
}

.ehq-text-opacity-75 {
  --tw-text-opacity: 0.75 !important
}

.ehq-underline {
  text-decoration: underline !important
}

.ehq-opacity-50 {
  opacity: 0.5 !important
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

.ehq-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.ehq-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.hover\:ehq-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.focus\:ehq-shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

/* Stop purging. */

@media (min-width: 576px) {
  .sm\:ehq-mt-0 {
    margin-top: 0px !important
  }

  .sm\:ehq-w-5\/6 {
    width: 83.333333% !important
  }

  .sm\:ehq-p-1 {
    padding: 0.25rem !important
  }

  .sm\:ehq-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
  }
}

@media (min-width: 768px) {
  .md\:ehq-w-4\/5 {
    width: 80% !important
  }

  .md\:ehq-w-5\/6 {
    width: 83.333333% !important
  }

  .md\:ehq-w-full {
    width: 100% !important
  }

  .md\:ehq-px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }

  .md\:ehq-pt-10 {
    padding-top: 2.5rem !important
  }

  .md\:ehq-pb-6 {
    padding-bottom: 1.5rem !important
  }

  .md\:ehq-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
  }
}

@media (min-width: 992px) {
  .lg\:ehq-w-3\/6 {
    width: 50% !important
  }

  .lg\:ehq-w-5\/12 {
    width: 41.666667% !important
  }

  .lg\:ehq-w-8\/12 {
    width: 66.666667% !important
  }

  .lg\:ehq-w-9\/12 {
    width: 75% !important
  }

  .lg\:ehq-w-full {
    width: 100% !important
  }

  .lg\:ehq-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
  }
}

@media (min-width: 1200px) {
  .xl\:ehq-w-5\/12 {
    width: 41.666667% !important
  }

  .xl\:ehq-w-6\/12 {
    width: 50% !important
  }

  .xl\:ehq-w-full {
    width: 100% !important
  }

  .xl\:ehq-px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }

  .xl\:ehq-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important
  }
}

@media (min-width: 1600px) {
}

